<template>
  <div class="admin-view-container">
    <admin-space size="medium" class="admin-methods-btns" align="center" style="padding-top: 25px">
      <el-button v-p="[70001]" icon="el-icon-plus" type="primary" @click="openPost()">创建模版</el-button>
    </admin-space>
    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column prop="templateName" label="标题"></el-table-column>
        <el-table-column prop="creationTime" label="创建时间" :formatter="$formatter.date(true)"></el-table-column>
        <el-table-column prop="useNum" label="使用情况"></el-table-column>
        <!-- <el-table-column
          prop="templateType"
          label="会员服务类型"
          :formatter="$formatter.dict($dict.templateType)"></el-table-column> -->
        <el-table-column label="操作" width="200" v-if="$utils.hasPermission([70002, 70003])">
          <template slot-scope="scope">
            <admin-space class="admin-table-links">
              <el-link v-p="[70002]" size="medium" type="primary" @click="openPost(scope.row)">查看</el-link>
              <el-link
                v-p="[70003]"
                size="medium"
                type="primary"
                @click="openDel(scope.row)"
                :disabled="scope.row.useNum != 0">
                删除
              </el-link>
            </admin-space>
          </template>
        </el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {expenseTemplateGetList, expenseTemplateDelete} from '@/api/index';
import tableMinx from '@/mixin/table-page';

export default {
  mixins: [tableMinx(expenseTemplateGetList)],
  data() {
    return {
      loading: false,
      pickDateTime: [],
      tableQuery: {
        applyCompensationNo: '',
        applyCompensationStatus: '',
        startTime: '',
        endTime: '',
      },
    };
  },
  methods: {
    async openPost(row) {
      let vNode = this.$createElement((await import('./post.vue')).default, {
        key: Math.random(),
        props: {row: row},
        on: {
          success: () => {
            this.getTable();
          },
        },
      });

      this.$adminDialog(vNode, {
        title: row ? '查看' : '新增',
        width: '600px',
      });
    },

    openDel(row) {
      this.$adminConfirm('用以永久删除该数据').then(() => {
        this.loading = true;
        this.$utils.resolveRequest(expenseTemplateDelete(row), this, () => {
          this.getTable();
        });
      });
    },

    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>

<style></style>
